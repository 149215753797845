.App {
  margin: 0 auto;
  padding: 50px;
  height: 100%;
  position: relative;
  max-width: 1500px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  position: relative;
  color: #fff;
  z-index: 2; /* Augmentez le z-index pour placer .App-header au-dessus de .centered-div */
  margin-bottom: 40px;
}


.centered-div {
  position: relative;
  z-index: 1;
  font-size: 21px;
}

td {
  padding: 10px;
  /* height: 100%; */
  vertical-align: middle;
  /* display:inherit; */
  line-height: 1.2;
  /* border: 1px solid white; */
}

/* td:first-child{
  padding: 4px;
} */

.day {
  font-weight: bold;
  font-size: 52px;
}

td:first-child {
  padding: 4px 0;
}

@media all and (orientation: portrait) {
  
  .App {
    padding: 20px;
  }

  .App-header {
  text-align: center;
  margin: 0 auto;
  padding: 0px 0px 25px 0; 
  width: 100%;
  }
  
  h1 {
    font-size: 7em;
  }

  h2 {
    font-size: 28px;
    text-align: center;
  }

  .day {
    font-size: 21px;
  }

  td {
    padding: 0;
    display:inline-block;
    width:100%;
    text-align: center;
  }

  td:first-child {
    padding: 0;
  }

  .portrait {
    width: 100%; /* La largeur du portrait sera le complément de la largeur du dégradé */
    opacity: 0.3;
  }

  table {
    margin: 0 auto;
  }
}

h2 {
  /* text-transform: uppercase; */
  margin: 5px 0px;
}

table {
  border-collapse: collapse; /* Collapse borders for a cleaner look */
  border-collapse:separate; 
  border-spacing: 0 1em;
}

tr:last-child {
border: none;
}

.App-link {
  color: #61dafb;
}

.comedyclub {
  color: #fbc693;
  font-weight: bold;
}