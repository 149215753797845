body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Helvetica Neue', 'Roboto', 'Arial',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background-color: #000;
  text-transform: uppercase;
  font-size: 14px;

  /* &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    //background-color: rgba(0,0,0,0.25);
    background-color: rgba(152, 66, 211, 0.63);
  } */
}

h1 {
  color: #fbc693;
  line-height: 0.9;
  font-size: 8em;
  margin: 0;
}

.social {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 5px 0px; /* Remove margins */
}

ul {
  list-style: none;
  padding: 0;
}

.social a {
  display: inline-block;
  margin: 0 10px;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: bold;
}

a:link, a:visited {
  color: #f5f5f5;
  text-decoration: none; 
}

a.youtube:hover {
  color:  #FF0000;
}
 
a.twitter:hover {
  color: #1DA1F2;
}

a.instagram:hover {
  color: #FF0069;
}

a.tiktok:hover {
  color: #00f2ea;
}

a:hover {
  color: #ccc;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: #000;
}

.portrait {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-image: linear-gradient(to right, black, rgba(117, 19, 93, 0)), url('bg3.jpeg');
  background-size: cover;
  background-position: top;
  filter: grayscale(100%);
}